@import "../../styles/form";

.loginForm {
    flex: 0 0 50%;
    max-width: 50%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    background-color: #e7e7e7;
    display: flex;
    flex-direction: column;
    padding: 100px 80px 50px;
}

.inputIcon {
    background-color: transparent !important;
    border-right-style: none !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.forgotPassword {
    cursor: pointer;
    color: #EF6668;
}

.formGroup {
    text-align: end;
}

.loginPage {
    //width: 400px !important;
    border-radius: 20px !important;
}

.name {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
}

.content {
    color: #000;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 23.8px */
    letter-spacing: -0.408px;
    margin-top: 10px !important;
}

.contentName {
    color: #000;
    font-feature-settings: 'case' on;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.408px;
}

.img {
    width: 120px;
    height: 120px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
}

.divImage {
    padding-top: 30px !important;
}

.signUpAccount {
    border-radius: 300px !important;
    font-size: 15px !important;
    display: flex;
    padding: 13px 10px !important;
    height: auto !important;
    color: white !important;
    background-color: #1E1F25 !important
}

#signUp:active {
    background-color: red !important;
}

.signUp {
    height: auto !important;
    border-radius: 300px !important;
    background-color: white !important;
    border: 2px solid #1E1F25 !important;
    color: #1E1F25 !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: -0.24px !important;
    text-align: center;
    text-transform: none !important;
}

.fullPage {
    min-height: 80vh !important;
}

.socialFullPage {
    min-height: 88vh !important;
}

.signUpFullPage {
    min-height: 80vh !important;
}


.welcomeFullPage {
    min-height: 50vh !important;
}

.ConfirmFullPage {
    min-height: 45vh !important;
}

.verticalLine {
    position: relative;
    text-align: center;
    padding-top: 20px;
}

.hr {
    padding-top: 30px !important;
}

.line {
    position: relative;
    text-align: center;
    margin-top: 20px;
}

.text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white; /* Optional: set background color */
    padding: 0 10px;
}

.signUpWithEmail {
    border-radius: 300px !important;
    font-size: 15px !important;
    display: flex;
    padding: 13px 10px !important;
    height: auto !important;
    background-color: white !important;
    border: 2px solid #1E1F25 !important;
    color: black !important;
    font-weight: 600 !important;

    a:hover {
        color: black !important
    }

    btn-warning:hover {
        color: black !important
    }

    btn:hover {
        color: black !important
    }
}

.cardBody {
    padding-top: 100px !important;
    padding-left: 70px !important;
    padding-right: 70px !important;

    @media (max-width: 768px) {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

.feedbackCardBody {
    padding-top: 50px !important;
    padding-left: 70px !important;
    padding-right: 70px !important;

    @media (max-width: 768px) {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

.cardHeaderText {
    font-weight: 700 !important;
    font-size: 28px !important;
    line-height: 24px !IMPORTANT;
    color: #1E1F25;
    line-height: 34px !important;
    padding-top: 30px;
}

.inputGroup {
    margin-bottom: 15px !important;
}

.socialIcon {
    width: 15px;
    height: 18px;
    position: absolute;
    left: 80px;

    @media (max-width: 768px) {
        left: 45px;
    }
}

.welcomeCardBody {
    padding-top: 10px !important;
    padding-right: 60px !important;
    padding-bottom: 50px !important;
    padding-left: 60px !important;
}


.signUpBtn {
    border-radius: 30px !important;
    height: 46px !important;
    background-color: #1E1F25 !important;
    font-size: 15px !important;
    font-weight: 600 !important;

    a:hover {
        color: white !important
    }

    btn:hover {
        color: white !important
    }
}

.cardFooter {
    padding-top: 13px !important;
    padding-right: 70px !important;
    padding-bottom: 13px !important;
    padding-left: 70px !important;

    @media (max-width: 768px) {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

.inputBox {
    height: 40px !important;
    font-size: 15px !important;
    color: #1E1F25 !important;
    background-color: rgba(92, 97, 128, 0.02) !important;
}

.appStoreImg {
    margin-top: 50px !important;
    cursor: pointer;
}

.cardBodyText {
    font-size: 17px !important;
    font-weight: 400 !important;
    color: black !important;
    // padding-bottom: 30px !important;
}


.playStoreImg {
    width: 169px;
    padding-top: 10px;
    cursor: pointer;
}

.verifyCardBody {
    padding: 30px 30px 50px 30px !important;
}

.signUpCardBody {
    padding-top: 50px !important;
    padding-left: 70px !important;
    padding-right: 70px !important;
    padding-bottom: 100px !important;

    @media (max-width: 768px) {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

.loaderRow {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 100px !important;
}

.visibilityIcon {
    background-color: white !important;
    border-left: black !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: end;
    text-align: left;
    padding-left: 60px !important;
    max-width: 37% !important;
    position: relative;
    z-index: 2;

    @media (max-width: 768px) {
        max-width: 100% !important;
    }
}

.col2 {
    display: flex !important;
    justify-content: center !important;
    text-align: center;
}

.col3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    text-align: left;
    padding-right: 0px !important;
}

.col4 {
    text-align: left;
    font-family: Patrick Hand SC;
    padding-left: 0px !important;
    font-size: 20px;
    color: #191919;
    transition: font-size 0.3s ease, background-color 0.3s ease;
}

.ptag1:hover,
.ptag2:hover,
.ptag3:hover,
.ptag4:hover {
    font-size: 22px;
    cursor: pointer;
    color: #191919 !important;
}

@media (max-width: 768px) {
    .ptag1:hover,
    .ptag2:hover,
    .ptag3:hover,
    .ptag4:hover {
        font-size: 18px;
        cursor: pointer;
        width: 200px;
        color: #191919 !important;
    }
}

.ptag1 {
    transform: rotate(2.55deg);
    transition: font-size 0.3s ease;
    line-height: 1.5;
    height: 80px;
    color: #19191980;
}

.ptag2 {
    transform: rotate(-2.7deg);
    color: #19191980;
    padding-left: 20px;
    transition: font-size 0.3s ease;
    line-height: 1.5;
    height: 80px;
}

.ptag3 {
    transform: rotate(2.18deg);
    color: #19191980;
    transition: font-size 0.3s ease;
    line-height: 1.5;
    height: 80px;
}

.ptag4 {
    transform: rotate(-1.35deg);
    color: #19191980 ;
    padding-left: 10px;
    transition: font-size 0.3s ease;
    line-height: 1.5;
    height: 80px;
}

.preee {
    font-size: 60px !important;
    font-weight: 700 !important;
    padding: 20px !important;
    color: rgba(25, 25, 25, 1) !important;
}

.preeeText {
    padding: 20px !important;
    font-size: 17px !important;
    color: rgba(25, 25, 25, 0.6) !important;
    font-weight: 500 !important;
}

.arrow {
    bottom: 135px;
    position: relative;
    height: 163.62px;
    width: 194.41px;

    @media (max-width: 768px) {
        display: none;
    }
    /* @media (max-width: 2000px) {
        bottom: 200px;
    }*/
}

.phoneimage {
    top: -27px;
    position: relative;
    height: 800px;
}

.col4text {
    top: 12%;
    position: absolute;

    @media (max-width: 768px) {
        font-size: 16px !important;
        position: static;
        justify-content: center;
        display: grid;
    }
}

.pocketText {
    font-size: 40px;
    font-weight: 700;

    @media (max-width: 768px) {
        margin-right: 20px;
        margin-left: 20px;
    }
}

.jamaica {
    font-size: 40px;
    font-weight: 700;
    background: linear-gradient(-215deg, #01BB57 7.39%, #FFA621 60.17%), linear-gradient(0deg, #ffffff, #ffffff);
    -webkit-background-clip: text;
    color: transparent;
}

.mainContent {
    background-color: #F3F3F6;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    height: 750px;
}

.JamaicaText {
    color: rgba(25, 25, 25, 0.6);
    width: 60%;
    font-size: 25px;
    padding-bottom: 25px;
}

.freeButten {
    background-color: #F3F3F6 !important;
    padding: 10px 20px !important;
    color: #2D2D2D !important;
    border: 2px solid #2D2D2D !important;
    border-radius: 25px !important;
    font-size: 18px !important;
}

.btn:hover,
.btn:focus,
.navbar .navbar-nav > a.btn:hover,
.navbar .navbar-nav > a.btn:focus {
    color: #F3F3F6 !important;
}

@media (max-width: 768px) {
    .JamaicaText {
        font-size: 16px !important;
    }
}



.onePreee {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    text-align: left;
}

.heading {
    font-size: 40px;
    font-weight: 700;
    color: #191919;
}

.headingText {
    font-size: 20px;
    font-weight: 500;
    color: #19191999;
    width: 413px;

    @media (max-width: 850px) {
        width: 300px;
    }
}

.row1 {
    padding: 10%;
    padding-bottom: 0px;
}

.row2 {
    padding-left: 10%;
    padding-right: 10%;
}

.col1 {
    padding: 10%;
    padding-top: 0px;
}

.friend {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: left;
}


//2ndpage


.termsOfService {
    overflow: hidden;
}

.termsMainContent {
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //text-align: start;
    flex-direction: column;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 3%;

    @media (max-width: 850px) {
        flex-wrap: inherit !important;
        padding-top: 10%;
    }
}

.termsText {
    font-size: 40px;
    font-weight: 700;
    color: #191919;
    text-align: start;
    word-wrap: break-word;

    @media (max-width: 850px) {
        font-size: 35px;
    }
}

.mText {
    font-size: 20px;
    font-weight: 600;
    color: #191919;
    text-align: start;
}

.ptag {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    color: #191919 !important;
    text-align: start;

    @media (max-width: 850px) {
        font-size: 18px;
    }
}

.ptag::marker {
    font-weight: 700;
}

.smallTitle {
    font-size: 25px;
    font-weight: 700;
    line-height: 32px;
    color: #191919;
    text-align: start;

    @media (max-width: 850px) {
        font-size: 22px;
    }
}

.listValue {
    font-weight: 700;
}

.link {
    color: #191919;
    word-wrap: break-word;
}

a:hover, a:focus {
    color: #191919 !important;
}

.bulletPoints {
    list-style-type: disc;
}

.ulList {
    list-style-type: disc;
    padding-left: 35px;
}

#fw-section-header {
    display: none;
}
