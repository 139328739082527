.modal-header {
  border-bottom: 1px solid $medium-gray;
  padding: 20px;
  text-align: center;
  display: block !important;

  &.no-border-header {
    border-bottom: 0 none !important;
    & .modal-title {
      margin-top: 20px;
    }
  }
  button.close {
    &:focus {
      outline: none;
    }
  }

  .modal-profile {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    text-align: center;
    line-height: 6.4;
    border: 1px solid rgba(0, 0, 0, 0.3);

    i {
      font-size: 30px;
    }
  }
}
.modal-dialog {
  &.modal-sm,
  &.modal-register {
    .modal-header {
      button.close {
        margin-top: 0;
      }
    }
  }
}

.modal-content {
  border: 0 none;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
  .modal-header {
    h6 {
      margin-top: 10px;
    }
  }
}

.modal-dialog {
  padding-top: 60px;
}
.modal-body {
  padding: 0px 0px;
  color: #000;
}
.modal-footer {
  border-top: 1px solid $medium-gray;
  padding: 0px;

  &.no-border-footer {
    border-top: 0 none;
  }
}
.modal-footer .left-side,
.modal-footer .right-side {
  display: inline-block;
  text-align: center;
  width: 50%;
  padding: 5px;
}
.modal-footer .btn-link {
  padding: 20px;
  width: 100%;
  margin: 0;
}
.modal-footer .divider {
  background-color: $medium-gray;
  display: inline-block;
  float: inherit;
  height: 63px;
  margin: 0px -3px;
  // position: absolute;
  width: 1px;
}
.modal-register .modal-footer {
  text-align: center;
  margin-bottom: 25px;
  padding: 20px 0 15px;
  span {
    width: 100%;
  }
}

//popup box css
///=======
.title {
  font: 600 13px/34px "Open Sans", sans-serif;
  margin-bottom: 15px;
  text-align: left;
  padding: 30px;
}

.formControl {
  background-color: #f1f1f1;
  border-radius: 30px;
  border: unset;
  padding: 5px 11px;
  box-sizing: unset;
}

.modalBody {
  position: unset !important;

  .formGroup {
    .formControl {
      background-color: #f1f1f1;
    }
  }

  .modalActions {
    display: flex;

    .saveBtn,
    .closeBtn {
      width: 265px;
      height: 54px;
    }

    .saveBtn {
      //background-color: $main-color;
      color: rgb(163, 0, 0);
      margin-right: 10px;
    }

    .closeBtn {
      background-color: #000;
      color: #fff;
    }
  }

  .modalTitle {
    font: 600 13px/34px;
    margin-bottom: 15px;
  }

  .companyName {
    // font: 600 18px/24px $main-font;
    margin-bottom: 15px;
  }

  .closeIcon {
    position: absolute;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #000;
    top: -10px;
    right: -10px;

    a {
      color: #fff;
      font: 400 16px/19px;
    }
  }

  .licenseDetails {
    margin-bottom: 30px;
    padding-block: 0px;

    .formGroup {
      margin-bottom: 0;
      max-width: 230px;
      box-sizing: 1px;
    }
  }
}

.value {
  color: #000;
}

.title {
  margin-right: 25px;
  color: #999;
  width: 230px;
  padding-block: 0px;
}
.detail {
  display: flex;
  align-items: center;
  font: 600 16px/30px;
  margin-bottom: 5px;
  padding-block: 0px;
}
.cancel {
  margin-right: 20px;
  margin-left: 43px;
  width: 194px;
  border-radius: 30px;
  background-color: #000;
}
.buy {
  margin-right: 20px;
  width: 194px;
  border-radius: 30px;
  background-color: #ffcf00;
  width: 193px;
}

.titleText {
  margin-right: 25px;
  color: #999;
  width: 230px;
  padding-block: 0px;
  margin-top: 8px;
  font-size: 20px;
  font: 600 13px/34px;
}
.crossButton {
  position: absolute;
  width: 30px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #000;
  top: -25px;
  right: -10px;
}
