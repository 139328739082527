@import "../../styles/variables";

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ccc;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c6c8ca;
    border-radius: 10px;
}

.flareLogo {
    margin-left: -15px;
    min-height: 30px;
    margin-top: 5px;
    min-width: 55px;
}

.userContent {
    align-items: center;

    .avatar {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 50%;
        font: 600 15px/22px $main-font;
        margin-right: 10px;

        @media all and (max-width: 576px) {
            width: 35px;
            height: 35px;
        }
    }

    .userName {
        font: 600 14px/20px $main-font;
        color: #fff;
    }
}

.user {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-top: 20px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.developers {
    margin-right: 15px !important;
}

.hr {
    content: "";
    position: absolute;
    bottom: 0;
    right: 15px;
    height: 0px;
    width: calc(100% - 30px);
    background-color: #212529;
    margin-bottom: 1px;
}


.pocketText {
    font-size: 25px;
    font-weight: 500;
    color: #191919;
}

.mainContent {
    background-color: #F3F3F6;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    height: 569px;
}

.freeButten {
    background-color: #2D2D2D !important;
    padding: 20px 40px !important;
    color: #FFFFFF !important;
    border: 2px solid #2D2D2D !important;
    border-radius: 50px !important;
    font-size: 40px !important;
    font-weight: 500 !important;

    @media (max-width: 768px) {
        font-size: 28px !important;
    }
}

.btn:hover,
.btn:focus,
.navbar .navbar-nav > a.btn:hover,
.navbar .navbar-nav > a.btn:focus {
    color: #FFFFFF !important;
}

.footerValue {
    top: 28%;
    position: relative;
    color: #191919B2;


    @media (max-width: 768px) {
        top: 25%;
        padding-left: 5%;
        padding-right: 5%;
    }
}

.subscribers {
    top: 27%;
    position: relative;
    color: #19191966;

    @media (max-width: 768px) {
        top: 25%;
        padding-left: 5%;
        padding-right: 5%;  
    }
}

.link1 {
    color: #191919B2;
    margin-right: 10px;
    margin-left: 10px;
}

.link {
    color: #191919B2;
    margin-right: 10px;
}

a:hover, a:focus {
    color: #191919 !important;
}
