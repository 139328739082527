@import "./variables";

.formGroup {
    margin-bottom: 20px;
    position: relative;

    .formControl {
        min-height: 54px;
        padding: 15px 20px;
        background-color: #fff;
        border-radius: 30px;
        border: unset;
        font: 600 18px/25px $main-font;
        color: #000;

        @media all and (max-width: 480px) {
            max-width: 100%;
            min-width: 100%;
        }

        &::placeholder {
            font: 600 18px/25px $main-font;
            color: #000;
        }

        &.phoneNumber {
            padding-left: 50px;
        }
    }

    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
    }

    &.password {
        img {
            left: unset;
            right: 20px;
            max-width: 20px;
        }
    }

    textarea {
        resize: none;

        &.descriptionArea {
            min-height: 150px;
            max-height: 150px;
        }

        &.benefitsArea {
            min-height: 90px;
            max-height: 90px;
        }
    }

    &.searchGroup {
        min-width: 350px;
        margin-right: 5px !important;
        margin-top: 11px !important;

        .formControl {
            padding-left: 50px;
            min-height: unset;
            height: 46px;
        }
    }
}

.details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .item {
    display: flex;
    align-items: center;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    .key {
      font-weight: bold;
      margin-right: 7px;
    }
  }
}

.parentGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.searchParent {
  display: flex;
  align-items: center;

  .searchBtn {
    margin-top: 0;
  }
}

.parentTwoChild {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .formGroup {
    flex: 0 0 calc(50% - 6px);
    max-width: calc(50% - 6px);
    position: relative;

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 30px;
    }
  }

  @media all and (max-width: 767px) {
    flex-wrap: wrap;

    .formGroup {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

.parentThreeChild {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .formGroup {
    flex: 0 0 calc(100% / 3 - 6px);
    max-width: calc(100% / 3 - 6px);
  }

  @media all and (max-width: 767px) {
    flex-wrap: wrap;

    .formGroup {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

#searchBtn {
    width: 150px;
    height: 46px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: $main-color;
    border-color: $main-color;
    font: 600 16px/22px $main-font;
    color: #000;
    margin-left: 2px;
    float: right;
    margin-top: 10px;
}
