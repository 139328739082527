@import "../../styles/variables";

.layoutWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media all and (max-width: 991px) {
        display: unset;
    }
}

.alink {
    color: #191919 !important;
    word-wrap: break-word;
}

.alink:hover {
    text-decoration: none;
}

.mainContent {
    background-color: #f1f1f1;
    padding: 15px 30px 0;
    width: 100%;
    margin-left: 135px;
    transition: margin 0.4s;
    color: black;

    @media all and (max-width: 991px) {
        width: unset;
    }

    @media all and (max-width: 767px) {
        margin-left: 165px;
    }

    @media all and (max-width: 576px) {
        margin-left: 0;
        margin-top: 104px;
        padding: 15px;
    }
}

.header {
    color: #1E1F25;
    text-align: center;
    font-family: Engagement;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 73.333% */
    letter-spacing: -0.408px;
    justify-content:center !important;
}

.preeeImg{
    height:27px;
    width:110px;
}

.navbar{
    background-color:white;
    text-align:center;
    height:60px;
}

.headerCompany {
    color: rgba(25, 25, 25, 1);
    margin-left: 38px;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: inter;

    @media all and (max-width: 991px) {
        margin-left: 0px !important;
        font-size: 16px !important;
    }
}

.logout {
    margin-right: 38px !important;

    @media all and (max-width: 991px) {
        justify-content: end !important;
        display: contents !important; 
    }
}

.months {
    background-color: rgba(45, 45, 45, 1) !important;
    padding: 10px 15px 10px 15px !important;
    color: #FFFFFF !important;
    border-radius: 20px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}
#fw-section-header {
    display: none;
}