// Font Variables
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

$backup-font: 'Poppins', sans-serif;
$main-font: "Open Sans", sans-serif;

// Colors
$main-color: #BB5496;


// Button style

@mixin main-btn {
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font: 600 16px/25px $main-font;
}

// Scaling
$vertical-menu-width: 135px;
$navbar-height: 60px;
