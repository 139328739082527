img {
  max-width: 100%;
  border-radius: $border-radius-small;
}
.img-raised {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: white;
  height: 30px;
}
.table .img-wrapper {
  background-color: #66615b;
  height: 31px !important;
  width: 31px !important;
}
