/*--------------------------------

nucleo-icons Web Font - built using nucleoapp.com
License - nucleoapp.com/license/

-------------------------------- */
@font-face {
  font-family: 'inter';
  src: url('../../../../fonts/nucleo-icons.eot');
  src: url('../../../../fonts/inter-variable.ttf') format('truetype');
      
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Patrick Hand SC';
    src: url('../../../../fonts/nucleo-icons.eot');
    src: url('../../../../fonts/PatrickHandSC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


/* all icon font classes list here */
